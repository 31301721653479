import React from 'react'

const Cliengo = () => {
    

    var ldk = document.createElement('script'); 
        ldk.type = 'text/javascript'; 
        ldk.async = true; 
        ldk.src = 'https://s.cliengo.com/weboptimizer/5b3a7d3ee4b0af8a24cdf95e/66c38f374144fc71c91f8459.js?platform=onboarding_modular'; 

    var s = document.getElementsByTagName('script')[0]; 
        s.parentNode.insertBefore(ldk, s);
    


  return (
  <></>
  )
}

export default Cliengo