import React from 'react'
import "./Reservas.css"

import groupIcon from "../../assets/icons/group.svg"
import doorIcon from "../../assets/icons/door.svg"
import bedIcon from "../../assets/icons/bed.svg"
import bathIcon from "../../assets/icons/bath.svg"
import fireplaceIcon from "../../assets/icons/fireplace.svg"
import sunIcon from "../../assets/icons/sun.svg"
import landscapeIcon from "../../assets/icons/landscape.svg"
import parkingIcon from "../../assets/icons/parking.svg"
import routeIcon from "../../assets/icons/route.svg"
import hikingIcon from "../../assets/icons/hiking.svg"
import wifiIcon from "../../assets/icons/wifi.svg"
import hotBathIcon from "../../assets/icons/hotbath.svg"

import map from "../../assets/img/map.png"
import Section from '../Section/Section'




const Reservas = ({t, redirectWhatsapp}) => {

    const redirectAirbnb = () => {
        window.location.href="https://www.airbnb.mx/rooms/1141825697943922064?guests=1&adults=1&s=67&unique_share_id=a115404c-8f29-4710-a0dd-140e06ae8dd7"
    }

    const redirectMaps = () => {
        window.location.href="https://maps.app.goo.gl/8UaCJo25m7FdMf3c6"

    
    }

    




    
  return (
    <div>
        
      <Section name={t("section.booking")}/>


        <div id='reservas' className='ReservasContainer'>
            <div className='ReservasMenuContainer'>
                {/* ----------------------------------MENU RESERVAS-------------------------------------- */}
                <div>
                    <ul className='ReservasMenuList'>
                        <li onClick={() => window.location.replace("/#servicios")}className='ReservasMenuListItem'>{t("bookings.menu.services")}</li>
                        <li onClick={() => window.location.replace("/#destacados")}className='ReservasMenuListItem'>{t("bookings.menu.high")}</li>
                        <li onClick={() => window.location.replace("/#ubicacion")} className='ReservasMenuListItem'>{t("bookings.menu.ubication")}</li>
                        <li onClick={() => window.location.replace("/#normas")}className='ReservasMenuListItem'>{t("bookings.menu.normas")}</li>
                        <li onClick={() => window.location.replace("/#tarifas")}className='ReservasMenuListItem'>{t("bookings.menu.tarifas")}</li>
                    </ul>
                </div>
        
        
                {/* -------------------------------------LISTA DE ICONOS----------------------------------- */}
                <div>
                    <ul className='ReservasIconList'>
                        <li className='IconListItem'><img className='IconListImg' src={groupIcon} alt="groupIcon" /><p className='IconListText'>{t("bookings.iconlist.family")}</p></li>
                        <li className='IconListItem'><img className='IconListImg' src={doorIcon} alt="doorIcon" /><p className='IconListText'>{t("bookings.iconlist.door")}</p></li>
                        <li className='IconListItem'><img className='IconListImg' src={bedIcon} alt="bedIcon" /><p className='IconListText'>{t("bookings.iconlist.bed")}</p></li>
                        <li className='IconListItem'><img className='IconListImg' src={bathIcon} alt="bathIcon" /><p className='IconListText'>{t("bookings.iconlist.bath")}</p></li>
                    </ul>
                </div>
        
                {/* ------------------------------------SERVICIOS------------------------------------ */}
                <div className='ReservasServicesContainer' id='servicios'>
                    {/* --------------------------------SERVICIOS NORMALES---------------------------------------- */}
                    <div className='animate__animated animate__fadeInLeft animate__delay-1s' id='servicios'>
                        <p className='ReservasTitle'>{t("bookings.services.titleNormal")}</p>
                        <ul className="ReservasServicesNormal">
                            <li className='ServicesNormalItem'><p className='ServicesNormalText'>{t("bookings.services.normal.t1")}</p></li>
                            <li className='ServicesNormalItem'><p className='ServicesNormalText'>{t("bookings.services.normal.t2")}</p></li>
                            <li className='ServicesNormalItem'><p className='ServicesNormalText'>{t("bookings.services.normal.t3")}</p></li>
                        </ul>
                    </div>
                    {/* ----------------------------------SERVICIOS DESTACADOS-------------------------------------- */}
                    <div className='animate__animated animate__fadeInLeft animate__delay-2s' id='destacados'>
                        <p className='ReservasTitle'>{t("bookings.services.titleHigh")}</p>
                        <ul className='ReservasServicesHigh'>
                            
                            <li className='ServicesHighItem'>
                                <img className='ServicesHighIcon' src={sunIcon} alt="sunIcon" />
                                <p className='ServicesHighTitle'>{t("bookings.services.high.lights")}</p>
                            </li>
                            <li className='ServicesHighItem'>
                                <img className='ServicesHighIcon' src={landscapeIcon} alt="landscapeIcon" />
                                <p className='ServicesHighTitle'>{t("bookings.services.high.view")}</p>
                            </li>
                            <li className='ServicesHighItem'>
                                <img className='ServicesHighIcon' src={parkingIcon} alt="parkingIcon" />
                                <p className='ServicesHighTitle'>{t("bookings.services.high.parking")}</p>
                            </li>
                            <li className='ServicesHighItem'>
                                <img className='ServicesHighIcon' src={routeIcon} alt="routeIcon" />
                                <p className='ServicesHighTitle'>{t("bookings.services.high.rides")}</p>
                            </li>
                            <li className='ServicesHighItem'>
                                <img className='ServicesHighIcon' src={hikingIcon} alt="hikingIcon" />
                                <p className='ServicesHighTitle'>{t("bookings.services.high.walk")}</p>
                            </li>
                            <li className='ServicesHighItem'>
                                <img className='ServicesHighIcon' src={wifiIcon} alt="wifiIcon" />
                                <p className='ServicesHighTitle'>{t("bookings.services.high.wifi")}</p>
                            </li>
                            
                        </ul>
                    </div>
                </div>
                <hr/>
                {/* -------------------------------UBICACIÓN----------------------------------------- */}
                <div id='ubicacion'>
                    <p className='ReservasTitle'>{t("bookings.ubication.title")}</p>
                    <div className='MapImgContainer'>
                        <img onClick={redirectMaps} className='MapImg' src={map} alt="map" />
                    </div>
                    <ul className="ReservasUbicationList">
                        <li className='UbicationListItem'>10km - Ruta Panorámica 109</li>
                        <li className='UbicationListItem'>19km - Cerro Catedral ({t("bookings.ubication.cerro")})</li>
                        <li className='UbicationListItem'>35km - Gruta Salamanca</li>
                        <li className='UbicationListItem'>45km - Ciudad de Rocha</li>
                        <li className='UbicationListItem'>47km - Bodega Garzón</li>
                        <li className='UbicationListItem'>50km - Pueblo Garzón</li>
                        <li className='UbicationListItem'>57km - El Edén</li>
                        <li className='UbicationListItem'>68km - Villa Serrana</li>
                        <li className='UbicationListItem'>80km - Cerro Artigas</li>
                        <li className='UbicationListItem'>80km - Salto del Penitente</li>
                        <li className='UbicationListItem'>90km - Puente Circular de Laguna Garzón</li>
                    </ul>
                </div>
                {/* --------------------NORMAS Y TARIFAS---------------------- */}
                <div className='ReservasNormasYTarifas'>
                    {/* ----------NORMAS----------- */}
                    <div id='normas' className="NormasContainer">
                        <p className='ReservasTitle'>{t("bookings.normas.title")}</p>
        
                        <ul className='NormasList'>
                            <li className='NormasItem'><p>{t("bookings.normas.item1")}</p></li>
                            <li className='NormasItem'><p>{t("bookings.normas.item2")}</p></li>
                        </ul>
                    </div>
        
                     {/* ------------TARIFAS----------- */}
                    <div id='tarifas' className="TarifasContainer">
                        <p className='ReservasTitle'>{t("bookings.tarifas.title")}</p>
                        <ul className='TarifasList'>
                            <li className='TarifasItem'><p>{t("bookings.tarifas.item1")}</p></li>
                            <li className='TarifasItem'><p>{t("bookings.tarifas.item2")}</p></li>
                        </ul>
                    </div>
                </div>
        
                {/* ---------BUTTON------------ */}
                <div className='ReservasButtonContainer'>
                        <div className='ReservasButtonTitle'><span>{t("bookings.buttonTitle")}</span></div>
                        <button className='ReservasButton' onClick={redirectWhatsapp}>{t("bookings.button")}</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Reservas