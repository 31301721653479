import React from 'react'
import "./SierrasDeValdivia.css"
import Section from '../Section/Section'
import "animate.css"

const SierrasDeValdivia = ({t}) => {
  return (
    <div>
      <Section name="SIERRAS DE VALDIVIA"/>

      <div className='VideoContainer'>
              <div className="VideoContent">
                  <p className="VideoContentText animate__animated animate__fadeInLeft animate__delay-1s ">{t("sierras.body1.p1")}<span className='TextBold'>{t("sierras.body1.p2")}</span>{t("sierras.body1.p3")}<span className="TextBold">{t("sierras.body1.p4")}</span>{t("sierras.body1.p5")}<span className="TextBold">{t("sierras.body1.p6")}</span>.</p>
                  <p className="VideoContentText animate__animated animate__fadeInLeft animate__delay-3s">{t("sierras.body2.p1")}<span className="TextBold">{t("sierras.body2.p2")}</span>{t("sierras.body2.p3")}</p>
                  <p className="VideoContentText animate__animated animate__fadeInLeft animate__delay-5s">{t("sierras.body3.p1")}<span className="TextBold">{t("sierras.body3.p2")}</span>{t("sierras.body3.p3")}<span className="TextBold">{t("sierras.body3.p4")}</span>{t("sierras.body3.p5")}<span className="TextBold">{t("sierras.body3.p6")}</span></p>
              </div>
      </div>
    </div>
  )
}

export default SierrasDeValdivia