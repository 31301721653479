import React from 'react'
import "./NavBar.css"
import logo from "../../assets/img/logo1.png"
import MenuLanguage from '../MenuLanguage/MenuLanguage'
import {FaBars} from "react-icons/fa"


const NavBar = ({isActive, ToggleClass, handleChangeLanguage, t}) => {
  return (
    <div>
        <header id='inicio' className={isActive ? "inactive" : "active"}>

            <div className="logoAndMenu">
                
                <img src={logo} alt="logo" className='NavBarLogo'/>
                
                <button className='navToggleButton' onClick={ToggleClass}>
                    <FaBars/>
                </button>
            </div>

            <nav className={isActive ? "menuInactive" : "menuActive"}>
                <button onClick={() => window.location.replace("#inicio")} className='menuLink'>{t("navbar.home")}</button>
                <button onClick={() => window.location.replace("#galeria")} className='menuLink'>{t("navbar.galery")}</button>
                <button onClick={() => window.location.replace("#sierras")} className='menuLink'>SIERRAS DE VALDIVIA</button>
                <button onClick={() => window.location.replace("#reservas")} className='menuLink'>{t("navbar.booking")}</button>
                <button onClick={() => window.location.replace("#contacto")} className='menuLink'>{t("navbar.contact")}</button>
            </nav>
            
            <MenuLanguage handleChangeLanguage={handleChangeLanguage}/>

        </header>   
    </div>
  )
}

export default NavBar