import React from 'react'
import "./Section.css"

const Section = (props) => {
  return (
    <div className='GaleryContainerDivision'>
            <hr className='GaleryContainerDivisonLine'/>
            <span className='GaleryContainerTitle'>{props.name}</span>
            <hr className='GaleryContainerDivisonLine'/>
    </div>

  )
}

export default Section