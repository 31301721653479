import React from 'react'
import "./Banner.css"
import logoLight from "../../assets/img/Recurso 3.png"
import 'animate.css'

const Banner = ({t}) => {
  return (
    <div className='Banner'>
      <div className='BannerLogo'>
        <img className="logoLight animate__animated animate__fadeIn animate__slower" src={logoLight} alt="" />
      </div>
      <div className='bodyText'>
        <span className='bodyTextTitle1 animate__animated animate__fadeInLeft animate__delay-1s'>{t("banner.title1")}</span>
        <span className='bodyTextTitle2 animate__animated animate__fadeInLeft animate__delay-1s'>{t("banner.title2")}</span>
        <p className='bodyTextMain animate__animated animate__fadeInLeft animate__delay-2s'>{t("banner.body1")}</p>
        <p className='bodyTextMain animate__animated animate__fadeInLeft animate__delay-3s'>{t("banner.body2")}</p>
      </div>
    </div>
  )
}

export default Banner