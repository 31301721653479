import React from 'react'
import "./Home.css"
import LogoLight from "../../assets/img/Recurso 3.png"

const Home = ({ToggleHomeActive}) => {
  return (
    <div className='HomeContainer'>

        <div className='HomeImgContainer'>
            <img className='HomeImg animate__animated animate__fadeIn animate__slower ' src={LogoLight} alt="casaarmonia" />
        </div>

        <button onClick={ToggleHomeActive} className='HomeButton animate__animated animate__fadeIn animate__slower animate__delay-2s'>Iniciar</button>
    </div>
  )
}

export default Home