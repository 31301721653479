import React from 'react'
import "./Social.css"
import { FaInstagram, FaRegEnvelope, FaWhatsapp } from 'react-icons/fa'


const Social = () => {

  const redirectWhatsapp = () => {window.location.href="https://api.whatsapp.com/send?phone=59897259227"}

  const redirectEmail = () => {window.location.href="mailto:info@casaarmonia.com.uy"}

  const redirectInstagram = () => {window.location.href="https://www.instagram.com/casa.armonia.uruguay"}


  return (
    <div className='SocialContainer'>
        <div className="SocialRowsContainer">

            <div onClick={redirectWhatsapp} className="SocialRow"> <FaWhatsapp className='SocialIcon'/> <span className='SocialRowText'>+598 97 259 227</span></div>

            <div onClick={redirectEmail} className="SocialRow"> <FaRegEnvelope className='SocialIcon'/> <span className='SocialRowText'>info@casaarmonia.com.uy</span></div>

            <div onClick={redirectInstagram} className="SocialRow"> <FaInstagram className='SocialIcon'/> <span className='SocialRowText'>@casa.armonia.uruguay</span></div>

        </div>
    </div>
  )
}

export default Social