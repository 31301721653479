import React from 'react'
import "./WhatsappButton.css"
import { FaWhatsapp } from 'react-icons/fa'

const WhatsappButton = ({redirectWhatsapp}) => {
  return (
    <div className='Whatsapp'>
        <div onClick={redirectWhatsapp} className='WhatsappButtonContainer'>
            <FaWhatsapp className='WhatsappButton'/>
        </div>
    </div>
  )
}

export default WhatsappButton