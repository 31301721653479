import React from 'react'
import "./PreviewImg.css"

const PreviewImg = ({handleToggle, photos, idImg}) => {

  console.log(photos[idImg.src])
  return (
    <div onClick={handleToggle} className='PreviewImgContainer'>
        <img className='PreviewImgBig' src={photos[idImg].src} alt="" />
    </div>
  )
}

export default PreviewImg