import React from 'react'
import "./Contacto.css"
import Form from '../Form/Form'
import Social from '../Social/Social'

import LogoDark from "../../assets/img/logo2.png"
import Section from '../Section/Section'

const Contacto = ({t, redirectWhatsapp}) => {
  return (
    <div>
      <Section name={t("section.contact")}/>
      <div className='ContactoContainer'>
          <Form t={t}/>
          <div className='SocialAndLogo'>
            <Social/>
            <div className='ContactoLogoContainer'>
              <img onClick={redirectWhatsapp} className='ContactoLogo' src={LogoDark} alt="" />
            </div>
          </div>
      
      </div>
    </div>
  )
}

export default Contacto