import React, { useState } from 'react'

import "./MenuLanguage.css"

import { IoMdGlobe } from "react-icons/io";

const MenuLanguage = ({handleChangeLanguage}) => {

    
    
    const [isFolded, setFolded] = useState("false");
    
    const ToggleFolded = () => {
        setFolded(!isFolded);
    }
    
    



    return (
        <div className="LanguageMenuContainer" >

            <IoMdGlobe className='LanguageMenuButton' onClick={ToggleFolded}/>


            <div className={isFolded ? "folded" : "unfolded"}>
                <div className="LanguageMenu">
                    <a className='LanguageMenuItem' onClick={()=>{handleChangeLanguage("es")}}>ES</a>
                    <a className='LanguageMenuItem' onClick={()=>{handleChangeLanguage("en")}}>EN</a>
                    <a className='LanguageMenuItem' onClick={()=>{handleChangeLanguage("pt")}}>PT</a>
                </div>
            </div>
        </div>
    )
}

export default MenuLanguage