

export const photoData = [
    {
        id: 1,
        src: "https://i.ibb.co/NsCBb9S/img1.png"
    },
    {
        id: 2,
        src: "https://i.ibb.co/FgXN3Tc/img2.png"
    },
    {
        id: 3,
        src: "https://i.ibb.co/2FsC9f4/img3.png"
    },
    {
        id: 4,
        src: "https://i.ibb.co/zHFjgmg/img4.png"
    },
    {
        id: 5,
        src: "https://i.ibb.co/30WscCY/img5.png"
    },
    {
        id: 6,
        src: "https://i.ibb.co/Xx1MMF1/img6.png"
    },
    {
        id: 7,
        src: "https://i.ibb.co/NsCBb9S/img1.png"
    },
    {
        id: 8,
        src: "https://i.ibb.co/FgXN3Tc/img2.png"
    },
    {
        id: 9,
        src: "https://i.ibb.co/2FsC9f4/img3.png"
    },
    {
        id: 10,
        src: "https://i.ibb.co/zHFjgmg/img4.png"
    },
    {
        id: 11,
        src: "https://i.ibb.co/30WscCY/img5.png"
    },
    {
        id: 12,
        src: "https://i.ibb.co/Xx1MMF1/img6.png"
    },];