import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import toast, { Toaster } from 'react-hot-toast';
import "./Form.css"

const Form = ({t}) => {

    const [AlertOn, setAlertOn] = useState("false")



    

    const notifySuccess = () => {
        toast.success('Mensaje enviado correctamente.')
        setAlertOn("true")
    };

    const notifyError = () => {
        toast.error('Error al envíar, intente nuevamente..')
        setAlertOn("true")
    };

    const form = useRef();

   

    const sendEmail = (e) => {

        e.preventDefault();

        emailjs
            .sendForm('service_jmry4dd', 'template_0hay328', form.current, {
                publicKey: 'iz4nO2VaKDeyC2kEn',
            })

            .then(()=>{
                notifySuccess()
                document.getElementById("ContactForm").reset();
            },

            (error) => {
                notifyError()
                console.log(error.text)
                document.getElementById("ContactForm").reset();
            }
        )

    }


  return (

    <div className='ContactoFormContainer'>

        {AlertOn ? <Toaster/> : undefined }

        <form id="ContactForm" className='ContactoForm' ref={form} onSubmit={sendEmail}>

            <div className="FormInputContainer">
                <label className='FormLabel' htmlFor="name">{t("form.name")}</label>
                <input className='FormInput' type="text" id='name' name='user_name'/>
            </div>

            <div className="FormInputContainer">
                <label className='FormLabel' htmlFor="number">{t("form.number")}</label>
                <input className='FormInput' type="number" id='number' name='user_number'/>
            </div>

            <div className="FormInputContainer">
                <label className='FormLabel' htmlFor="email">{t("form.email")}</label>
                <input className='FormInput' type="text" id='email' name='user_email'/>
            </div>

            <div className="FormInputContainer">
                <label className='FormLabel' htmlFor="msg">{t("form.msg")}</label>
                <textarea className='FormInputMensaje' name="message" id="msg" cols="30" rows="10"></textarea>
            </div>


            <button type='sumbit' value='Send' className='FormButton'>{t("form.button")}</button>

        </form>
    </div>
    
  )
}

export default Form